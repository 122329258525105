import React from "react";
import type { IInjectProps, IVentureListItem } from "@aspen/model";
import { BRAND, VENTURE_SHARES } from "@aspen/model";
import styles from "./VentureItem.module.less";
import {
  FORMATWEBP,
  FUNDS_PATHS,
  HOME_PATH,
  i18nUtil,
  VENTURES_PATHS,
  systemDetection,
  safeParseJsonArrayWithReport
} from "@aspen/libs";
import Image from "next/image";
import { RightOutlined } from "@ant-design/icons";
import { withRouter } from "next/router";

interface IProps extends IInjectProps {
  data: IVentureListItem;
  fromPage?: string;
  tabName?: string; // 代表当前item属于 Primary 还是 Secondary
  productType?: "Venture" | "Fund";
}

interface IState {}

class VentureItem extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(id: string): void {
    const { tabName, router, productType } = this.props;
    if (productType === "Fund") {
      router.push({
        pathname: `${FUNDS_PATHS.FUNDS_DETAIL}/${id}`
      });
    } else {
      router.push({
        pathname: `${VENTURES_PATHS.VENTURES_DETAIL}/${id}`,
        query: { tab: tabName }
      });
    }
  }

  render(): React.ReactNode {
    const intl = i18nUtil.t();
    const system = systemDetection();
    const viewDetail: string = intl["ventures.item.viewDetail"];
    const ventures = this.props?.data;
    const formatwebp = typeof window != "undefined" && (localStorage.getItem(FORMATWEBP) || "");
    const imageFormat = formatwebp ? `?x-oss-process=image/${formatwebp}` : "";
    const now = new Date().getTime();
    const parsedPurchaseInfo = safeParseJsonArrayWithReport(
      ventures?.purchaseInformation,
      `Error venture purchaseInformation: ${ventures?.id}`
    );
    return (
      <div className={styles.ventureItemContent}>
        {/* 这个svg一直上传失败 */}
        {now > ventures.endTime && (
          <div className={styles.closedTag}>
            <Image
              unoptimized
              width="47"
              height="30"
              src={require("@aspen/assets/images/ventureClosed.svg").default}
              alt=""
            />
          </div>
        )}
        <div className={styles.bgImg}>
          <Image
            unoptimized
            width="368"
            height="160"
            src={`${
              now < ventures.endTime
                ? ventures?.listPictureOnSaleAddressWeb
                : ventures?.listPictureStopAddressWeb
            }${imageFormat}`}
            alt=""
          />
        </div>

        <div className={styles.content}>
          <div className={styles.logo}>
            <Image
              unoptimized
              width="70"
              height="70"
              style={{ borderRadius: "50%" }}
              src={`${ventures.listIconAddressWeb}${imageFormat}`}
              alt=""
            />
          </div>
          <p className={styles.ventureTitle}>
            <span>{ventures.name}</span>
          </p>
          <p className={styles.brief}>
            {this.props.fromPage === HOME_PATH && system === BRAND ? (
              <span className={styles.shares}>
                {/* @ts-ignore */}
                {intl[VENTURE_SHARES[ventures?.shares]]}
              </span>
            ) : null}
            {ventures.briefIntroduction}
          </p>

          <div className={styles.itemBrief}>
            {parsedPurchaseInfo?.length > 0
              ? parsedPurchaseInfo?.map((item, index) => (
                  <div className={styles.simpleDesc} key={index}>
                    <p className={styles.itemTitle}>{item?.title}</p>
                    <p className={styles.itemValue}>{item?.subTitle}</p>
                  </div>
                ))
              : null}
          </div>
          <div className={styles.action} onClick={() => this.handleClick(ventures?.id)}>
            <span className="pointerNone">{viewDetail}</span>
            <RightOutlined className="pointerNone" style={{ marginLeft: 6 }} />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(VentureItem);
