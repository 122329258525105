"use client";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "@aspen/widgets/login/Login.module.less";
import { useRouter } from "next/router";
import { getConfigInfo, logout } from "@aspen/services";
import {
  clearCookies,
  clearLocalStorage,
  i18nUtil,
  reportGaInfo,
  DEVICE_ID,
  ERROR,
  HOME_PATH,
  TRADE_PATH_DEFAULT_PATH,
  isClient,
  LOGIN_PATHS,
  OPEN_BIND_PHONE,
  MEMBER_INFO,
  OPERATE_CUSTOMER_EMAIL,
  USER_AUTH,
  AUTHORIZATION_TYPE,
  USER_ROLE,
  getSessionStorageTokenKey,
  IS_AUTHENTICATED,
  cookieUtil,
  CUSTOMER_ID,
  USER,
  CUSTOMER_INFO,
  KYC_CERTIFIED_LEVEL,
  KYC_CERTIFIED_STATUS,
  IS_GOOGLE_Certified,
  SHOW_RECAPTCHA,
  IS2FA,
  ASSETANALYSIS,
  IP_COUNTRY_URL,
  ADGM_TRADE_PATH_DEFAULT_PATH,
  systemDetection
} from "@aspen/libs";
import { message, ModalBase } from "@aspen/ui";
import { clearAllWallet } from "@aspen/store/slices/WalletSlice";
import { clearAllYield } from "@aspen/store/slices/YieldSlice";
import { useAppDispatch } from "@aspen/store/hooks";
import { Tabs } from "antd";
import Link from "next/link";
import { ACCOUNT_TYPE, BRAND } from "@aspen/model";
import {
  LoginLayoutPortal,
  LoginTabItem,
  LoginTabViewPortal,
  LoginBaseHandle,
  handlerAfterSuccessfulLogin
} from "@aspen/widgets";

export interface IPageProps {
  customerHandlerAfterSuccessLogin?: (data: any) => void;
}

const PortalLogin: React.FC<IPageProps> = (props) => {
  const intl = i18nUtil.t();
  const system = systemDetection();
  const router = useRouter();
  const [allowRegister, setAllowRegister] = useState(true);
  const [regStopVisible, setRegStopVisible] = useState(false);

  const dispatch = useAppDispatch();

  // 获取配置信息，afsOpen 是否开启阿里云验证开关
  const getConfigData = () => {
    // 设置默认值为true，若接口有问题，不会导致登录接口显示缺乏参数 header
    localStorage.setItem(SHOW_RECAPTCHA, "true");
    localStorage.setItem(IS2FA, "true");
    // FIXME: 显示隐藏资产分析入口
    localStorage.setItem(ASSETANALYSIS, "false");

    getConfigInfo().then((res) => {
      if (res.code == "0") {
        localStorage.setItem(SHOW_RECAPTCHA, res.data?.afsOpen);
        localStorage.setItem(IS2FA, res.data?.is2fa);
        // 资产分析
        localStorage.setItem(ASSETANALYSIS, res.data?.assetAnalysis);
      }
    });
  };

  // 处理退出登录 信息清除等
  const init = () => {
    const error = localStorage.getItem(ERROR);
    if (error) {
      message.error(intl[error]);
    }
    localStorage.getItem(DEVICE_ID) && reportGaInfo(3);

    // 退出登录接口
    logout().finally(() => {
      clearLocalStorage();
      clearCookies();
      // 清除redux的数据
      clearReduxData();
    });
  };

  // 清除redux的数据
  const clearReduxData = useCallback(() => {
    dispatch(clearAllWallet());
    dispatch(clearAllYield());
  }, [dispatch]);

  const fetchIpCountry = () => {
    fetch(IP_COUNTRY_URL)
      .then((res) => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((data) => {
        if ((data?.country ?? "").toUpperCase() == "CN") {
          setAllowRegister(false);
        }
      });
  };

  useEffect(() => {
    fetchIpCountry();
    getConfigData();
    init();
    // Prefetch the pages
    router.prefetch(HOME_PATH);
    router.prefetch(system === BRAND ? TRADE_PATH_DEFAULT_PATH : ADGM_TRADE_PATH_DEFAULT_PATH);

    // 页面刷新时清除填充的sesstionStorage值
    if (isClient) {
      window.onbeforeunload = () => {
        sessionStorage.clear();
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  // 登录方式
  const [currentTab, setCurrentTab] = useState(ACCOUNT_TYPE.EMAIL);

  const handleChangeTab = useCallback((value) => {
    setCurrentTab(value);
  }, []);

  const tabItems = useMemo(() => {
    const items = [
      {
        key: ACCOUNT_TYPE.EMAIL,
        label: (
          <LoginTabItem
            key={ACCOUNT_TYPE.EMAIL}
            activeTab={ACCOUNT_TYPE.EMAIL}
            currentTab={currentTab}
            title={intl["login.email.tab"]}
          />
        )
      }
    ];
    if (OPEN_BIND_PHONE) {
      items.push({
        key: ACCOUNT_TYPE.PHONE,
        label: (
          <LoginTabItem
            key={ACCOUNT_TYPE.PHONE}
            activeTab={ACCOUNT_TYPE.PHONE}
            currentTab={currentTab}
            title={intl["login.phone.tab"]}
          />
        )
      });
    }
    return items;
  }, [currentTab, intl, OPEN_BIND_PHONE]);

  // 保存会员信息
  const saveMemberInfo = useCallback((customer_info) => {
    const customerInfo = customer_info ? JSON.parse(customer_info) : {};
    const { vipEndTime, vipLevel } = customerInfo;
    let memberInfo = { vipEndTime, vipLevel };
    if (vipEndTime && !vipLevel) {
      // 如果level为null 默认置为L0
      memberInfo.vipLevel = "L0";
    }
    localStorage.setItem(MEMBER_INFO, JSON.stringify(memberInfo));
  }, []);

  // 保存localStorage
  const setCustomerStorage = useCallback((resData) => {
    const {
      user_id,
      account_id,
      account_email, // 当前登录用户邮箱
      user_email, // 对子账号来说，是主账号邮箱
      jwtToken,
      isGoogleCertified,
      user_roles,
      customer_info,
      authorizationType,
      kycLevel, // L0 L1 L2
      kycStatus
    } = resData;

    // 子账号登陆时
    if (user_roles.join(",").toLocaleLowerCase() == USER_ROLE.ROLE_SUB) {
      localStorage.setItem(OPERATE_CUSTOMER_EMAIL, user_email);
    }
    localStorage.setItem(USER_AUTH, user_roles ? user_roles.join(",").toLocaleLowerCase() : "");
    localStorage.setItem(AUTHORIZATION_TYPE, authorizationType);
    sessionStorage.setItem(getSessionStorageTokenKey(location.origin), jwtToken ?? "");
    cookieUtil.set(getSessionStorageTokenKey(location.host), jwtToken ?? "");
    localStorage.setItem(IS_AUTHENTICATED, "true");
    sessionStorage.setItem(CUSTOMER_ID, account_id || user_id);
    sessionStorage.setItem(USER, account_email);
    cookieUtil.set(USER, account_email);
    cookieUtil.set(CUSTOMER_ID, account_id || user_id);

    sessionStorage.setItem(CUSTOMER_INFO, customer_info);
    localStorage.setItem(IS_GOOGLE_Certified, Number(isGoogleCertified).toString());
    localStorage.setItem(KYC_CERTIFIED_LEVEL, kycLevel);
    localStorage.setItem(KYC_CERTIFIED_STATUS, kycStatus);
  }, []);

  // 自定义的登录后的一些处理
  const handleSuccessfulLogin = useCallback((data) => {
    const { customerHandlerAfterSuccessLogin } = props;
    customerHandlerAfterSuccessLogin && customerHandlerAfterSuccessLogin(data);
    const { customer_info } = data;
    saveMemberInfo(customer_info);
    setCustomerStorage(data);
    handlerAfterSuccessfulLogin && handlerAfterSuccessfulLogin(data);
  }, []);

  const toRegister = () => {
    if (allowRegister) {
      router.push(LOGIN_PATHS.LOGIN_REGISTER);
    } else {
      setRegStopVisible(true);
    }
  };

  const onCancel = () => setRegStopVisible(false);

  return (
    <>
      <LoginBaseHandle />
      <div className={styles.form}>
        <div className={styles.container}>
          <div className={`${styles.commonTips} text-right`} style={{ marginBottom: "42px" }}>
            {intl["login.noAccount"]}&nbsp;
            <span onClick={toRegister} className={styles.toRegister}>
              {intl["register.now"]}
            </span>
          </div>
          <p className={styles.title}>{intl["login.text"]}</p>
          <Tabs
            items={tabItems}
            onChange={handleChangeTab}
            className={styles.tab}
            activeKey={currentTab}
          />
          <div
            className={styles.inputFillBox}
            style={{ display: currentTab === ACCOUNT_TYPE.EMAIL ? "block" : "none" }}>
            <LoginTabViewPortal
              accountType={ACCOUNT_TYPE.EMAIL}
              customerHandlerAfterSuccessLogin={handleSuccessfulLogin}
            />
          </div>
          <div
            className={styles.inputFillBox}
            style={{ display: currentTab === ACCOUNT_TYPE.PHONE ? "block" : "none" }}>
            <LoginTabViewPortal
              accountType={ACCOUNT_TYPE.PHONE}
              customerHandlerAfterSuccessLogin={handleSuccessfulLogin}
            />
          </div>
          <div className="text-center" style={{ marginTop: "32px" }}>
            <Link href={LOGIN_PATHS.LOGIN_RESET}>
              <div className={styles.commonTipsForget}>{intl["login.forget"]}</div>
            </Link>
          </div>
        </div>
      </div>
      {regStopVisible ? (
        <ModalBase
          buttonType="only"
          okText={<span>{intl["button.ok"]}</span>}
          open={regStopVisible}
          title={intl["register.stop.title"]}
          onOk={onCancel}
          onCancel={onCancel}
          maskClosable={false}>
          <div style={{ marginTop: 32, marginBottom: 8 }}>{intl["register.stop.content"]}</div>
        </ModalBase>
      ) : null}
    </>
  );
};

export const PagePortalLogin: React.FC<IPageProps> = React.memo(LoginLayoutPortal(PortalLogin));
