import React, { useEffect, useState } from "react";
import { Col, Row, Spin, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import styles from "@aspen/theme/Wallet.module.less";
import {
  convertUSD2USDC,
  decimalPointNoMoreX,
  i18nUtil,
  numberToThousands,
  reportEvent,
  GA_EVENT_NAME,
  systemDetection,
  getReferenceCurrencyBySystem
} from "@aspen/libs";
import { amfDeduct, availableSpots, getAMFList, getAMFSummary } from "@aspen/services";
import { message, WithEmptyContent } from "@aspen/ui";
import { ModalSettleManagementFee } from "@aspen/widgets";
import { ADGM_BRAND, AUM_FEE_STUTAS } from "@aspen/model";

function AUMFee() {
  const intl = i18nUtil.t();
  const system = systemDetection();
  const referenceCurrency = getReferenceCurrencyBySystem(system);
  const loadMore = intl["button.loadMore"];
  const currency_text = intl["column.currency"];
  const feeTotal_text = intl["wallet.AUM.fee.total"];
  const feeSettled_text = intl["wallet.AUM.fee.settled"];
  const feeUnsettled_text = intl["wallet.AUM.fee.unsettled"];
  const feeSettle = intl["wallet.AUM.fee.button.settle"];
  const status_text = intl["column.status"];
  const title_aum_fee = intl["title.aum.fee"];
  const column_aum_fee = intl["column.aum.fee"];
  const column_note = intl["column.note"];
  const month_text = intl["column.month"];
  const column_deduction_time = intl["column.deduction.time"];
  const table_title = intl["aum.table.title"];

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [assetInfo, setAssetInfo] = useState({
    settledAmount: 0,
    unsettledAmount: 0,
    failedAmount: 0
  });
  const [balanceFee, setBalanceFee] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showSettleBtn, setShowSettleBtn] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    const visible =
      system === ADGM_BRAND && assetInfo?.unsettledAmount + assetInfo?.failedAmount > 0;
    setShowSettleBtn(visible);
  }, [assetInfo]);

  // 初始化数据,
  const initData = () => {
    fetchAMFSummary();
    fetchAMFList();
    fetchAvaibleSpots();
  };

  //资产管理费总额
  const fetchAMFSummary = () => {
    getAMFSummary().then((res) => {
      if (res?.code == "0") {
        setAssetInfo(res?.data);
      } else {
        message.error(intl?.[res?.msg] ?? res?.msg);
      }
    });
  };

  //资产管理费历史
  const fetchAMFList = (type?: string) => {
    setLoading(true);
    const param = {
      limit: 10,
      nextToken
    };
    getAMFList(param)
      .then((res) => {
        if (res?.code == "0") {
          if (type === "loadMore") {
            setDataSource(dataSource.concat(res.data?.rows || []));
          } else {
            setDataSource(res.data?.rows || []);
          }
          setNextToken(res.data?.nextToken);
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //可用余额
  const fetchAvaibleSpots = () => {
    const params = {
      currency: referenceCurrency
    };
    availableSpots(params).then((balanceRes) => {
      if (balanceRes?.code == "0") {
        setBalanceFee(balanceRes.data?.[referenceCurrency]);
      }
    });
  };

  //扣除资产管理费
  const fetchSettled = () => {
    setConfirmLoading(true);
    amfDeduct()
      .then(async (res) => {
        if (res?.code == "0" && res?.data) {
          await new Promise((resolve) => {
            setTimeout(() => {
              resolve(null);
            }, 3000);
          }); // 延迟3秒
          Promise.all([fetchAMFSummary(), fetchAMFList()]);
        } else {
          message.error(intl["wallet.aum.fee.deduct.failed"]);
        }
      })
      .finally(() => {
        setShowModal(false);
        setConfirmLoading(false);
      });
  };
  const columns = [
    {
      title: month_text,
      dataIndex: "amfDate",
      render: (value) => <span>{`${value?.substring(0, 4)}-${value?.substring(4, 6)}`}</span>, //目前前端截取
      align: "center"
    },
    {
      title: currency_text,
      dataIndex: "currency",
      render: (value) => <span>{convertUSD2USDC(value)}</span>,
      align: "center"
    },
    {
      title: column_aum_fee,
      dataIndex: "amount",
      render: (value) => <span>{numberToThousands(decimalPointNoMoreX(value, 2))}</span>,
      align: "center"
    },
    {
      title: column_deduction_time,
      dataIndex: "deductedTime",
      render: (value) => <span>{value ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : "--"}</span>,
      align: "center"
    },
    {
      title: status_text,
      dataIndex: "status",
      render: (value) => (
        <span>{value == AUM_FEE_STUTAS.SETTLED ? intl["settled"] : intl["unsettled"]}</span>
      ),
      align: "center"
    },
    ...(system === ADGM_BRAND
      ? [
          {
            width: 200,
            title: column_note,
            dataIndex: "remark",
            render: (value) => (
              <span
                style={{
                  display: "flex",
                  justifyContent: "center"
                }}>
                <span
                  style={{
                    textAlign: "left"
                  }}>
                  {value || "--"}
                </span>
              </span>
            ),
            align: "center"
          }
        ]
      : [])
  ];
  return (
    <section className={`${styles.wallet} customer`}>
      <div className="customerTop">
        <Row className="top">
          <Col span={24}>
            <p className="title">{title_aum_fee}</p>
          </Col>
        </Row>
      </div>
      <Spin spinning={loading}>
        <div className={styles.AUMFee}>
          <Row className={styles.assetContent}>
            <Col span={showSettleBtn ? 7 : 8} className={styles.assetItem}>
              <p className={styles.assetValue}>
                {`$ ${numberToThousands(
                  decimalPointNoMoreX(
                    assetInfo?.settledAmount + assetInfo?.unsettledAmount + assetInfo?.failedAmount,
                    2
                  )
                )}`}
              </p>
              <p className={styles.assetText}>{feeTotal_text}</p>
            </Col>
            <Col
              span={showSettleBtn ? 7 : 8}
              className={`${styles.assetItem} ${styles.assetItemMiddle}`}>
              <p className={styles.assetValue}>
                {`$ ${numberToThousands(decimalPointNoMoreX(assetInfo?.settledAmount, 2))}`}
              </p>
              <p className={styles.assetText}>{feeSettled_text}</p>
            </Col>
            <Col span={showSettleBtn ? 7 : 8} className={styles.assetItem}>
              <p className={styles.assetValue}>
                {`$ ${numberToThousands(
                  decimalPointNoMoreX(assetInfo?.unsettledAmount + assetInfo?.failedAmount, 2)
                )}`}
              </p>
              <p className={styles.assetText}>{feeUnsettled_text}</p>
            </Col>
            {showSettleBtn ? (
              <Col span={3} className={styles.assetItem}>
                <div className={styles.settleText} onClick={() => setShowModal(true)}>
                  {feeSettle}
                </div>
              </Col>
            ) : null}
          </Row>

          <div className={styles.tableArea}>
            <div className={styles.tableTitle}>{table_title}</div>
            <WithEmptyContent showEmpty>
              <Table
                className={styles.table}
                bordered={false}
                dataSource={dataSource}
                columns={columns as ColumnsType}
                pagination={false}
              />
            </WithEmptyContent>
            {nextToken && dataSource.length >= 10 ? (
              <div
                className={styles.loadMore}
                onClick={() => {
                  fetchAMFList("loadMore");
                  reportEvent({ moduleName: GA_EVENT_NAME.wallet.loadMoreAum });
                }}>
                {loadMore}
              </div>
            ) : null}
          </div>
        </div>
      </Spin>
      {showModal ? (
        <ModalSettleManagementFee
          visible={showModal}
          loading={confirmLoading}
          handleConfirm={fetchSettled}
          handleCancel={() => {
            setShowModal(false);
          }}
          unSettleFee={assetInfo.unsettledAmount + assetInfo.failedAmount}
          balanceFee={balanceFee}
        />
      ) : null}
    </section>
  );
}

export const PageAumFee = AUMFee;
