import dynamic from "next/dynamic";

export const ModalAccumulatorDetail = dynamic(() => import("./ModalAccumulatorDetail"), {
  ssr: false
});

export const ModalDepositAndWithdrawUSDC = dynamic(() => import("./ModalDepositAndWithdrawUSDC"), {
  ssr: false
});

export const ModalFixedYieldDetail = dynamic(() => import("./ModalFixedYieldDetail"), {
  ssr: false
});
export const ModalNewFixedYieldDetail = dynamic(() => import("./ModalNewFixedYieldDetail"), {
  ssr: false
});

export const ModalSnowballDetail = dynamic(() => import("./ModalSnowballDetail"), {
  ssr: false
});

export const ModalStruturalDetail = dynamic(() => import("./ModalStruturalDetail"), {
  ssr: false
});

export const ModalVaultStorageType = dynamic(() => import("./ModalVaultStorageType"), {
  ssr: false
});
export const ModalVentureUpdates = dynamic(() => import("./ModalVentureUpdates"), {
  ssr: false
});
export const ModalFundUpdates = dynamic(() => import("./ModalFundUpdates"), {
  ssr: false
});
export const ModalSettleManagementFee = dynamic(() => import("./ModalSettleManagementFee"), {
  ssr: false
});
export const ModalWhitelistDetail = dynamic(() => import("./ModalWhitelistDetail"), {
  ssr: false
});
