import React, { useEffect, useState } from "react";
import type { RadioChangeEvent } from "antd";
import { Breadcrumb, Button, Input, message, Radio, Select, Spin } from "antd";
import { useRouter } from "next/router";
import Link from "next/link";
import styles from "@aspen/theme/Wallet.module.less";
import { GA_EVENT_NAME, WALLET_PATHS, convertUSD2USDC, i18nUtil, reportEvent } from "@aspen/libs";
import {
  addWhitelistAddress,
  checkWithdrawAddressFormat,
  getAssetCurrencyConfig,
  getTravelRuleVasp
} from "@aspen/services";
import type {
  IBusinessVerificationHeaderModel,
  ICurrencyAsset,
  ICurrencyConfig,
  IReqSecurityVerifyHeader,
  IVaspType
} from "@aspen/model";
import { IBindingBusinessType, ICustomerType, WALLET_WHITELIST_TAB_TYPE } from "@aspen/model";

import { ModalResult, ModalBusinessVerification, WithRiskReminder } from "@aspen/widgets";
import { userCountriesData } from "@aspen/assets/offlineData/usersCountries";
import Image from "next/image";

const { Option } = Select;

interface IProps {}

const AddAddress: React.FC<IProps> = () => {
  const intl = i18nUtil.t();
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(true);
  const [coinList, setCoinList] = useState<ICurrencyConfig[]>([]);
  const [chainList, setChainList] = useState<ICurrencyAsset[]>([]);
  const [currentCoinInfo, setCurrentCoinInfo] = useState<ICurrencyConfig>();
  const [currentChainInfo, setCurrentChainInfo] = useState<ICurrencyAsset>();
  const [addressInfo, setAddressInfo] = useState<string>("");
  const [xrpTag, setXrpTag] = useState<string>("");
  const [addressFormat, setAddressFormat] = useState<boolean>(true);
  const [noteList, setNoteList] = useState<IVaspType[]>([]);
  const [note, setNote] = useState<IVaspType | undefined>();
  const [type, setType] = useState<ICustomerType>(ICustomerType.INDIVIDUAL);
  const [addSuccessModalVisible, setAddSuccessModalVisible] = useState<boolean>(false);
  const [showBusinessVerification, setShowBusinessVerification] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [location, setLocation] = useState<string[]>([]);

  const successMapData = [
    {
      label: intl["wallet.withdraw.white.history.denomination"],
      value: convertUSD2USDC(currentCoinInfo?.currency ?? "")
    },
    {
      label: intl["wallet.withdraw.white.history.network"],
      value: currentChainInfo?.chainName
    },
    {
      label: intl["wallet.withdraw.white.history.address"],
      value: addressInfo
    },
    {
      label: intl["wallet.withdraw.white.add.tag"],
      value: xrpTag
    },
    {
      label: intl["wallet.withdraw.white.add.note"],
      value: note?.name || "--"
    },
    {
      label: intl["wallet.withdraw.white.add.originator"],
      value: type
    }
  ];

  useEffect(() => {
    initData();
  }, []);
  useEffect(() => {
    if (currentCoinInfo) {
      checkAddress();
    }
  }, [currentCoinInfo]);

  const initData = () => {
    getAssetCurrencyConfig({})
      .then((res) => {
        if (res?.code == "0") {
          const list = res?.data ?? [];
          setCoinList(list);
          if (list.length) {
            setCurrentCoinInfo(list[0]);
            setChainList(list[0]?.assets);
            setCurrentChainInfo(list[0]?.assets[0]);
          }
        }
      })
      .then(() => {
        fetchNoteList("");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleChangeCoin = (currency: string) => {
    const selectInfo = coinList.filter((e) => e.currency === currency)[0];
    setCurrentCoinInfo(selectInfo);
    setXrpTag("");
    setChainList(selectInfo?.assets);
    setCurrentChainInfo(selectInfo?.assets[0]);
  };
  const handleChangeChain = (chainName: string) => {
    const selectInfo = chainList.filter((e) => e.chainName === chainName)[0];
    setCurrentChainInfo(selectInfo);
  };

  const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddressInfo(e.target.value);
  };
  const handleChangeXrpTag = (e: React.ChangeEvent<HTMLInputElement>) => {
    setXrpTag(e.target.value);
  };

  const checkAddress = () => {
    if (addressInfo === "") {
      setAddressFormat(true);
      return;
    }
    if (
      currentChainInfo &&
      currentChainInfo.asset === "XRP" &&
      (addressInfo === "" || xrpTag === "")
    )
      return true;
    const params = {
      asset: currentChainInfo?.asset ?? "",
      address: addressInfo,
      memo: xrpTag
    };
    checkWithdrawAddressFormat(params).then((res) => {
      if (res?.code == "0") {
        if (res.data === true) {
          setAddressFormat(true);
        } else {
          setAddressFormat(false);
        }
      }
    });
  };

  const fetchAddAddress = (verifyParam: IBusinessVerificationHeaderModel) => {
    setLoading(true);
    const countryInfo = userCountriesData?.find((item) => item.id === country);
    const params = {
      asset: currentChainInfo?.asset ?? "",
      chainName: currentChainInfo?.chainName ?? "",
      address: addressInfo,
      memo: xrpTag,
      customerType: type.toUpperCase(),
      firstName,
      lastName,
      companyName,
      city,
      location: JSON.stringify(location),
      vaspDid: note?.did || "",
      vaspName: note?.name || "",
      ...(countryInfo?.id
        ? {
            country: JSON.stringify({
              id: countryInfo?.id,
              nationality: countryInfo?.nationality,
              commonName: countryInfo?.commonName
            })
          }
        : {})
    };
    let headerParams: IReqSecurityVerifyHeader = {
      businessType: IBindingBusinessType.whitelistAddAddress,
      smsType: 0
    };
    headerParams = { ...headerParams, ...verifyParam };
    reportEvent({
      moduleName: GA_EVENT_NAME.whithdraw.addAddress,
      detailParams: params
    });
    addWhitelistAddress(params, headerParams)
      .then((res) => {
        if (res?.code == "0") {
          setShowBusinessVerification(false);
          setAddSuccessModalVisible(true);
        } else {
          message.error(intl[res.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchNoteList = (namePrefix: string) => {
    setNoteList([]);
    getTravelRuleVasp(namePrefix).then((res) => {
      if (res?.code === "0") {
        setNoteList(res?.data);
      }
    });
  };
  const handleChangeCustomerType = (customerType: ICustomerType) => {
    if (type === customerType) {
      return;
    }
    setType(customerType);
  };
  const saveStatus = () => {
    const xrpStatus = currentChainInfo?.asset !== "XRP" || xrpTag;
    const individualStatus = type === ICustomerType.INDIVIDUAL && !!firstName && !!lastName;
    const institutionStatus = type === ICustomerType.INSTITUTION && !!companyName;
    return addressInfo && addressFormat && (individualStatus || institutionStatus) && xrpStatus;
  };

  return (
    <Spin spinning={loading}>
      <Breadcrumb style={{ marginBottom: "24px" }}>
        <Breadcrumb.Item>{intl["kyc.certify.location"]}:</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link href={WALLET_PATHS.WALLET}>{intl["wallet"]}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{intl["wallet.withdraw.select.address.btn.add"]}</Breadcrumb.Item>
      </Breadcrumb>
      <div className={styles.addNewAddressContent}>
        <p className={styles.title}>{intl["wallet.withdraw.white.add.title"]}</p>
        <p className={styles.groupTitle}>
          {intl["wallet.withdraw.white.list.input.label.title.address.info"]}
        </p>
        <div className={styles.selectView}>
          <div className={styles.selectCoinView}>
            <p className={styles.label}>{intl["wallet.withdraw.white.history.denomination"]}</p>
            <Select
              className={styles.selectCoin}
              value={currentCoinInfo?.currency}
              style={{ width: 160 }}
              onChange={(e) => handleChangeCoin(e)}>
              {coinList.map((item, index) => {
                return (
                  <Option key={index} value={item.currency}>
                    {convertUSD2USDC(item.currency)}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className={styles.selectCoinView}>
            <p className={styles.label}>{intl["wallet.withdraw.white.history.network"]}</p>
            <Select
              className={styles.selectCoin}
              value={currentChainInfo?.chainName}
              style={{ width: 160 }}
              onChange={(e) => handleChangeChain(e)}>
              {chainList.map((item, index) => {
                return (
                  <Option key={index} value={item.chainName}>
                    {item?.chainName}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
        <div className={styles.itemView}>
          <p className={styles.label}>{intl["wallet.withdraw.white.history.address"]}</p>
          <Input
            bordered={false}
            onChange={handleChangeAddress}
            placeholder={intl["placeholder.enter"]}
            onBlur={checkAddress}
            value={addressInfo}
          />
          {!addressFormat && (
            <div className={styles.errorTips}>
              <p>{intl["wallet.withdraw.white.address.error"]}</p>
            </div>
          )}
        </div>
        {currentChainInfo?.asset === "XRP" && (
          <div className={styles.itemView}>
            <p className={styles.label}>{intl["wallet.withdraw.white.add.tag"]}</p>
            <Input
              bordered={false}
              onChange={handleChangeXrpTag}
              placeholder={intl["placeholder.enter"]}
              onBlur={checkAddress}
              value={xrpTag}
            />
          </div>
        )}
        <div className={styles.itemView}>
          <p className={styles.label}>{intl["wallet.withdraw.white.history.note"]}</p>
          <Select
            showSearch
            allowClear
            size="large"
            style={{ width: "100%" }}
            onClear={() => fetchNoteList("")}
            onSearch={fetchNoteList}
            onChange={(e) => {
              const selectedNote = noteList.find((item) => item.did === e);
              setNote(selectedNote);
            }}
            placeholder={intl["placeholder.enter.to.select"]}
            optionFilterProp="label"
            options={noteList?.map((d) => ({
              value: d.did,
              label: d.name
            }))}
          />
        </div>
        <div className={styles.itemView}>
          <Radio.Group
            value={type}
            onChange={(e: RadioChangeEvent) => handleChangeCustomerType(e.target.value)}>
            <Radio value={ICustomerType.INDIVIDUAL}>{intl["adgm.wallet.radio.individual"]}</Radio>
            <Radio value={ICustomerType.INSTITUTION}>{intl["adgm.wallet.radio.institution"]}</Radio>
          </Radio.Group>
        </div>
        <p className={styles.groupTitle}>
          {intl["wallet.withdraw.white.list.input.label.title.name"]}
        </p>
        {type === ICustomerType.INDIVIDUAL && (
          <div className={styles.containerView}>
            <div className={styles.itemView}>
              <p className={styles.label}>{intl["adgm.wallet.input.label.first.name"]}</p>
              <Input
                bordered={false}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder={intl["placeholder.enter"]}
                value={firstName}
              />
            </div>
            <div className={styles.itemView}>
              <p className={styles.label}>{intl["adgm.wallet.input.label.last.name"]}</p>
              <Input
                bordered={false}
                onChange={(e) => setLastName(e.target.value)}
                placeholder={intl["placeholder.enter"]}
                value={lastName}
              />
            </div>
          </div>
        )}
        {type === ICustomerType.INSTITUTION && (
          <div className={styles.itemView}>
            <p className={styles.label}>{intl["adgm.wallet.input.label.company.name"]}</p>
            <Input
              bordered={false}
              onChange={(e) => setCompanyName(e.target.value)}
              placeholder={intl["placeholder.enter"]}
              value={companyName}
            />
          </div>
        )}

        <p className={styles.groupTitle}>
          {intl["wallet.withdraw.white.list.input.label.title.address"]}
        </p>
        <div className={styles.containerView}>
          <div className={styles.itemView}>
            <p className={styles.label}>{intl["adgm.wallet.input.label.city"]}</p>
            <Input
              bordered={false}
              onChange={(e) => setCity(e.target.value)}
              placeholder={intl["placeholder.enter"]}
              value={city}
            />
          </div>
          <div className={styles.itemView}>
            <p className={styles.label}>{intl["adgm.wallet.input.label.country"]}</p>
            <Select
              size="large"
              style={{ width: "100%" }}
              placeholder={intl["placeholder.select"]}
              optionFilterProp="label"
              showSearch
              onChange={(e) => setCountry(e)}>
              {userCountriesData.map((opt) => (
                <Option value={opt.id} key={opt.id} label={opt.commonName} fullOption={opt}>
                  {opt?.flagIsImage ? (
                    <span style={{ marginRight: "5px" }}>
                      <Image unoptimized src={opt?.nationalFlag} width={15} height={10} alt="" />
                    </span>
                  ) : (
                    <span
                      style={{ paddingRight: "10px" }}
                      dangerouslySetInnerHTML={{ __html: opt?.nationalFlag }}
                    />
                  )}
                  {opt.commonName}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className={styles.containerView}>
          <div className={styles.itemView}>
            <p className={styles.label}>{intl["adgm.wallet.input.label.address1"]}</p>
            <Input
              bordered={false}
              onChange={(e) => {
                const newLocation = [...location];
                newLocation[0] = e.target.value;
                setLocation(newLocation);
              }}
              placeholder={intl["placeholder.enter"]}
              value={location[0]}
            />
          </div>
          <div className={styles.itemView}>
            <p className={styles.label}>{intl["adgm.wallet.input.label.address2"]}</p>
            <Input
              bordered={false}
              onChange={(e) => {
                const newLocation = [...location];
                newLocation[1] = e.target.value;
                setLocation(newLocation);
              }}
              placeholder={intl["placeholder.enter"]}
              value={location[1]}
            />
          </div>
        </div>
        <Button
          type="primary"
          style={{ width: 170 }}
          disabled={!saveStatus()}
          onClick={() => {
            setShowBusinessVerification(true);
          }}>
          {intl["wallet.withdraw.white.add.save"]}
        </Button>
      </div>
      {showBusinessVerification ? (
        <ModalBusinessVerification
          confirmLoading={loading}
          visible={showBusinessVerification}
          businessType={IBindingBusinessType.whitelistAddAddress}
          confirm={(res) => {
            fetchAddAddress(res);
          }}
          cancel={() => {
            setShowBusinessVerification(false);
          }}
        />
      ) : null}
      {addSuccessModalVisible ? (
        <ModalResult
          open={addSuccessModalVisible}
          resultType="success"
          onOk={() => {
            setAddSuccessModalVisible(false);
            setLoading(true);
            router.push({
              pathname: WALLET_PATHS.WALLET_WITHDRAW_WHITELIST_MANAGEMENT,
              query: {
                tab: WALLET_WHITELIST_TAB_TYPE.InReview
              }
            });
          }}
          onCancel={() => {
            router.back();
          }}
          okText={intl["wallet.withdraw.white.add.success.btn.whitelist"]}
          title={intl["wallet.withdraw.white.add.success.title"]}
          text={intl["wallet.withdraw.white.add.success.content"]}
          list={successMapData}
          maskClosable={false}
        />
      ) : null}
    </Spin>
  );
};

export const PageAdgmWhitelistAddAddress = WithRiskReminder(AddAddress);
