import dynamic from "next/dynamic";

export const InputSearch = dynamic(() => import("./symbolDrawerContent/InputSearch"), {
  ssr: false
});

export const Favorites = dynamic(() => import("./symbolDrawerContent/Favorites"), {
  ssr: false
});

export const Market = dynamic(() => import("./symbolDrawerContent/Market"), {
  ssr: false
});

export const Holding = dynamic(() => import("./symbolDrawerContent/Holding"), {
  ssr: false
});

export const SymbolsTable = dynamic(() => import("./symbolDrawerContent/SymbolsTable"), {
  ssr: false
});

export const Recommend = dynamic(() => import("./symbolDrawerContent/Recommend"), {
  ssr: false
});

export const RecommendItem = dynamic(() => import("./symbolDrawerContent/RecommendItem"), {
  ssr: false
});

export const AddAndEditDraw = dynamic(() => import("./symbolDrawerContent/AddAndEditDraw"), {
  ssr: false
});
export const AddFavorites = dynamic(() => import("./symbolDrawerContent/AddFavorites"), {
  ssr: false
});
export const EditFavorites = dynamic(() => import("./symbolDrawerContent/EditFavorites"), {
  ssr: false
});
export const Kline = dynamic(() => import("./Kline"), {
  ssr: false
});
export const PlaceOrder = dynamic(() => import("./PlaceOrder"), {
  ssr: false
});
export const OrderList = dynamic(() => import("./OrderList"), {
  ssr: false
});
export const SymbolInfo = dynamic(() => import("./SymbolInfo"), {
  ssr: false
});
export const SymbolDrawer = dynamic(() => import("./symbolDrawer"), {
  ssr: false
});
export const OrderBook = dynamic(() => import("./OrderBook"), {
  ssr: false
});
